import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { shortenWalletId } from "../Normalized";
import moment from "moment";
const ShipingDrawer = ({ shipinfo }) => {
  const [copyMsg, setCopyMsg] = useState("Copy");
  const [expandedRows, setExpandedRows] = useState([]);
  const [tooltipVisible, setTooltipVisible] = useState({});

  const toggleRowExpansion = (index) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = [...prevExpandedRows];
      newExpandedRows[index] = !newExpandedRows[index];
      return newExpandedRows;
    });
  };

  const handleCopyWalletId = (walletId, dataIndex) => {
    navigator.clipboard.writeText(walletId);
    setTooltipVisible({ [dataIndex]: true });
    setCopyMsg("Copied");

    // Hide the tooltip after a short delay
    setTimeout(() => {
      setTooltipVisible({ [dataIndex]: false });
      setCopyMsg("Copy");
    }, 1000);
  };

  const DummyData = [
    {
      lot: "200",
      totalQuantity: 150,
      Distributors: "Testdistri@gmial",
      TrackingId: "sdfsdfgdfg2387965",
      TxId: "sdfsdfgdfg2387965",
      Shippers: "FedX",
      ShipDates: "08-08-2023",
      ExpireDate: "08-08-2023",
      details: [
        {
          quantity: 10,
          Distributors: "Testdistri@gmial",
          TrackingId: "sdfsdfgdfg2387965",
          TxId: "sdfsdfgdfg2387965",
          Shippers: "FedX",
          ShipDates: "08-08-2023",
          ExpireDate: "08-08-2023",
        },
        {
          quantity: 150,
          Distributors: "Testdistri@gmial",
          TrackingId: "sdfsdfgdfg2387965",
          TxId: "sdfsdfgdfg2387965",
          Shippers: "FedX",
          ShipDates: "08-08-2023",
          ExpireDate: "08-08-2023",
        },
      ],
    },
    {
      lot: "500",
      totalQuantity: 130,
      Distributors: "distri@gmial",
      TrackingId: "sdfsdfgdfg2387965",
      TxId: "sdfsdfgdfg2387965",
      Shippers: "FedX1",
      ShipDates: "08-08-2023",
      ExpireDate: "08-08-2023",
      details: [
        {
          quantity: 10,
          Distributors: "Testdistri@gmial",
          TrackingId: "sdfsdfgdfg2387965",
          TxId: "sdfsdfgdfg2387965",
          Shippers: "FedX",
          ShipDates: "08-08-2023",
          ExpireDate: "08-08-2023",
        },
        {
          quantity: 150,
          Distributors: "Testdistri@gmial",
          TrackingId: "sdfsdfgdfg2387965",
          TxId: "sdfsdfgdfg2387965",
          Shippers: "FedX",
          ShipDates: "08-08-2023",
          ExpireDate: "08-08-2023",
        },
      ],
    },
  ];

  return (
    <>
      <div className="product-list-drawer-table">
        <Table responsive hover>
          <thead>
            <tr>
              <th>Lots</th>
              <th>Quantities</th>
              <th>Distributors</th>
              <th>Tracking Ids</th>
              <th>Transaction Hash</th>
              <th>Shippers</th>
              <th>Ship Dates</th>
              <th>Expire Dates</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shipinfo.alldata.map((lot, index) => (
              <React.Fragment key={index}>
                <tr
                  className={`lot-row ${
                    expandedRows[index] ? "expanded" : "no-expanded"
                  }`}
                >
                  <td>{lot.lot}</td>
                  <td>{lot.amount}</td>
                  <td>
                    {lot.organization}
                    <span className="plus-totalcount">
                      {"+" + lot.totalcount}
                    </span>
                  </td>
                  <td>
                    {shortenWalletId(lot.trackingNo, 6, 4)}
                    <span className="plus-totalcount">
                      {"+" + lot.totalcount}
                    </span>
                  </td>
                  <td>
                    {shortenWalletId(lot.transactionId, 6, 4)}{" "}
                    <span className="plus-totalcount">
                      {"+" + lot.totalcount}
                    </span>
                    <span
                      className="plus-copy-icon"
                      tooltip={copyMsg}
                      flow="up"
                    >
                      <img
                        onClick={() =>
                          handleCopyWalletId(lot.transactionId, index)
                        }
                        className="copy-btn"
                        src="/images/icons/copy-icon.svg"
                        alt="icon"
                      />
                    </span>
                  </td>
                  <td>{lot.shipper}</td>
                  <td>{moment(lot.shipDate).format("YYYY-MM-DD")}</td>
                  <td className="expire-date-color">
                    {moment(lot.ExpireDate).format("YYYY-MM-DD")}
                  </td>
                  <td>
                    <div
                      className={
                        expandedRows[index]
                          ? "expend-icon"
                          : "expend-icon close-expend-icon"
                      }
                      onClick={() => toggleRowExpansion(index)}
                    >
                      {expandedRows[index] ? (
                        <img src="/images/icons/up-arrow.svg" alt="icon" />
                      ) : (
                        <img src="/images/icons/down-arrow.svg" alt="icon" />
                      )}
                    </div>
                  </td>
                </tr>
                {expandedRows[index] &&
                  lot.details.map((detail, detailIndex) => (
                    <tr className="expanded-row">
                      <td></td>
                      <td>{detail.amount}</td>
                      <td>{detail.organization}</td>
                      <td className="td-with-copy">
                        {shortenWalletId(detail.trackingNo, 6, 4)}
                        {/* <span tooltip={copyMsg} flow="up">
                          <img
                            onClick={() =>
                              handleCopyWalletId(detail.trackingNo, index)
                            }
                            className="copy-btn"
                            src="/images/icons/copy-icon.svg"
                            alt="icon"
                          />
                        </span> */}
                      </td>
                      <td className="td-with-copy">
                        {shortenWalletId(detail.transactionId, 6, 4)}
                        <span tooltip={copyMsg} flow="up">
                          <img
                            onClick={() =>
                              handleCopyWalletId(detail.transactionId, index)
                            }
                            className="copy-btn"
                            src="/images/icons/copy-icon.svg"
                            alt="icon"
                          />
                        </span>
                      </td>
                      <td>{detail.shipper}</td>
                      <td>{moment(detail.shipDate).format("YYYY-MM-DD")}</td>
                      <td className="expire-date-color">
                        {moment(detail.ExpireDate).format("YYYY-MM-DD")}
                      </td>

                      <td></td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ShipingDrawer;
