import React, { useState, useEffect, useRef, Fragment } from "react";
import arrow from "../../../images/down-arrow.png";
import DatePicker from "react-date-picker";
import { serviceGet, servicePost } from "../../../services/api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ShippingTutModal from "../Modals/ShippingTutModal";
import GyanConsulting from "./GyanConsulting";

const Shipping = () => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Select Product");
  const productVal = selected == "Select Product" ? "" : selected;

  // Lot dropdown
  const [isLotActive, setIsLotActive] = useState(false);
  const [selectedLot, setSelectedLot] = useState("Select Lots");
  const lot = selectedLot == "Select Lots" ? "" : selectedLot;

  // Distributor dropdown
  const [isDistributorActive, setIsDistributorActive] = useState(false);
  const [selectedDistributor, setSelectedDistributor] =
    useState("Select Wholesaler");
  const Wholesaler =
    selectedDistributor == "Select Wholesaler" ? "" : selectedDistributor;

  const [users, setUsers] = useState([]);
  const [shipDate, setShipDate] = useState();
  const [skuNumber, setskuNumber] = useState();
  const [expireDate, setExpireDate] = useState();
  const [shipData, setShipData] = useState({
   
    amount: "0",
    shipper: "",
    trackingNo: "",
  });

  const {amount, shipper, trackingNo } = shipData;

  const history = useHistory();
  const refOne = useRef(null);
  const today = new Date().toISOString().split("T")[0];

  // Get user form localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  // Access Dom and When Click Outside Close DropDowns...
  useEffect(() => {
    const hideOnClickOutside = (e) => {
      if (refOne.current && !refOne.current.contains(e.target)) {
        setIsActive(false);
        setIsLotActive(false);
        setIsDistributorActive(false);
      }
    };

    document.addEventListener("click", hideOnClickOutside);
    return () => {
      document.removeEventListener("click", hideOnClickOutside);
    };
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setShipData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setSelectedLot("Select Lots");
  }, [selected]);

  // get all products
  const getAllProducts = async () => {
    try {
      const allProducts = await serviceGet("api/distributor/allproducts");
      setProducts(allProducts);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get all users
  const getAllUsers = async () => {
    try {
      const allusers = await serviceGet("api/getUsers");
      setUsers(allusers);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // filterData basis of userEmail
  const filterData = products?.filter((data) => {
    return data.organization == userData.email;
  });

  // get uniqueProducts..
  const uniqueProducts = products?.reduce((acc, current) => {
    if (!acc[current.product]) {
      acc[current.product] = current;
    }
    return acc;
  }, {});
  const uniqueProduct = Object.values(uniqueProducts);

  // get Selected Product value...
  const selectedProduct = products.filter((data) => {
    return data.product == productVal && data.lot == lot;
  });

  useEffect(() => {
    if (selectedProduct) {
      setExpireDate(
        selectedProduct[0]?.expireDate ? selectedProduct[0]?.expireDate : ""
      );
    }
  }, [lot]);

  // Create Ship
  const createShipHandler = async () => {
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    if (formatDate(Date.now()) > formatDate(expireDate)) {
      alert("This Product expired");
      return;
    }

    if (
      productVal == "" ||
      lot == "" ||
      Wholesaler == "" ||
      skuNumber == "" ||
      amount == "" ||
      shipper == "" ||
      shipDate == "" ||
      expireDate == "" ||
      trackingNo == ""
    ) {
      alert("Please fill all Fields");
      return;
    }

    try {
      setIsLoading(true);
      const convertdateformat = new Date(expireDate);
      const convertedDate = convertdateformat.toISOString().slice(0, 10);
      console.log("convertedDate", convertedDate);
      const data = await servicePost("api/createShip", {
        product: productVal,
        organization: Wholesaler,
        skuNumber: skuNumber,
        lot: lot,
        amount: amount,
        shipper: shipper,
        trackingNo: trackingNo,
        shipDate: shipDate,
        expireDate: convertedDate,
        from: "Distributor",
        to: "Wholesaler",
      });

      if (data) {
        history.push("/shiping-history");
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        alert(err.response.data.message);
        setIsLoading(false);
      } else {
        alert("Something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  /// HandleKeyPress
  function handleKeyPress(event) {
    const regex = /^[0-9\b]+$/; // allows only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // hadleKey allow only Char
  function handleKeyCharPress(event) {
    const regex = /^[a-zA-Z ]+$/; // allows only characters and space
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }

  // Increment and Decrement Quantity
  const incrementAmount = () => {
    setShipData((prevData) => ({
      ...prevData,
      amount: Number(prevData.amount) + 1,
    }));
  };

  const decrementAmount = () => {
    if (shipData.amount > 0) {
      setShipData((prevData) => ({
        ...prevData,
        amount: Number(prevData.amount) - 1,
      }));
    }
  };

  return (
    <>
      <ShippingTutModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
      />

      <div className="create-product-main-wraaper">
        <div className="header-main-wrapper header-desktop-main-wrapper">
          <div className="header-main-wrap">
            <div className="header-logo">
              <h3> Shipping </h3>
            </div>

            <div className="logout-logo-wrap" onClick={onLogout}>
              <svg
                id="Logout"
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="41"
                viewBox="0 0 41 41"
              >
                <rect
                  id="Rectangle_906"
                  data-name="Rectangle 906"
                  width="41"
                  height="41"
                  fill="rgba(255,255,255,0)"
                />
                <rect
                  id="vuesax_bold_logout"
                  data-name="vuesax/bold/logout"
                  width="30"
                  height="30"
                  transform="translate(37 35) rotate(-180)"
                  fill="none"
                />
                <g
                  id="vuesax_bold_logout-2"
                  data-name="vuesax/bold/logout"
                  transform="translate(37.479 35.479) rotate(-180)"
                >
                  <g id="logout-2" data-name="logout">
                    <path
                      id="Vector"
                      d="M16.757,6.708V19.091c0,4.128-2.58,6.708-6.708,6.708H6.708C2.58,25.8,0,23.219,0,19.091V13.867H8.062a.967.967,0,0,0,0-1.935H0V6.708C0,2.58,2.58,0,6.708,0h3.354C14.19,0,16.769,2.58,16.757,6.708Z"
                      transform="translate(11.61 2.58)"
                      fill="#4b4b4b"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M3.3,4.318H9.026V6.253H3.3l2.67,2.67A.967.967,0,1,1,4.6,10.291L.281,5.969a.973.973,0,0,1,0-1.367L4.6.281a.973.973,0,0,1,1.367,0,.936.936,0,0,1,.284.684.957.957,0,0,1-.284.684L3.3,4.318Z"
                      transform="translate(2.583 10.194)"
                      fill="#4b4b4b"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,0H30.959V30.959H0Z"
                      transform="translate(30.959 30.959) rotate(180)"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>
              <p> Logout </p>
            </div>
          </div>
        </div>

        <div className="header-main-wrapper header-mobile-main-wrapper">
          <div className="mobile-header-main-wrap">
            <h3> Shipping </h3>
          </div>
          <div className="mobile-project-main-logo">
            <img src="/images/icons/manu-small-logo.png" alt="logo" />
          </div>
        </div>

        <div className="mobile-create-tutorial-wrap mobile-shipping-tutorial-wrap">
          <div className="tutorial-wrap-btn">
            <img
              onClick={() => setShowTutorialModal(true)}
              src="/images/icons/tutorial-icon.png"
              alt="icon"
            />
            <p> Tutorials </p>
          </div>
        </div>

        <div className="create-product-main-wrap">
          <div className="create-product-right-wrap shipping-product-form">
            <div className="card1">
              <div className="card-body">
                <form>
                  <div className="create-product-form row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Product</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsActive(!isActive);
                              setIsLotActive(false);
                              setIsDistributorActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {selected}
                              </p>
                            </div>

                            <img
                              className={
                                isActive
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src={arrow}
                              alt="arrow"
                            />
                          </div>
                          {isActive && (
                            <div className="dropdown-content" ref={refOne}>
                              {uniqueProduct &&
                                uniqueProduct?.map((option, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <div
                                        onClick={(e) => {
                                          setSelected(option.product);
                                          setskuNumber(option.skuNumber);
                                          setIsActive(false);
                                        }}
                                        className="dropdown-item"
                                      >
                                        <p> {option.product} </p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Shipper Name
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="shipper"
                          className="input-field-redux"
                          placeholder="Enter Shipper name"
                          onKeyPress={handleKeyCharPress}
                          onChange={onChangeHandler}
                          value={shipper}
                        />

                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/shipper-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Wholesaler</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsDistributorActive(!isDistributorActive);
                              setIsLotActive(false);
                              setIsActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/whole-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {selectedDistributor}
                              </p>
                            </div>

                            <img
                              className={
                                isDistributorActive
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src={arrow}
                              alt="arrow"
                            />
                          </div>
                          {isDistributorActive && (
                            <div
                              className="dropdown-content distributor-ref"
                              ref={refOne}
                            >
                              {users &&
                                users
                                  ?.filter((data) => {
                                    return data.organization == "Wholesaler";
                                  })
                                  .map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelectedDistributor(
                                              option.email
                                            );
                                            setIsDistributorActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p> {option.email} </p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label> Tracking number</label>
                      <div className="create-product-input">
                        <input
                          name="trackingNo"
                          className="input-field-redux"
                          placeholder="Enter Your Text"
                          onChange={onChangeHandler}
                          value={trackingNo}
                        />
                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/traking-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        sku number
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="skuNumber"
                          type="number"
                          placeholder="Enter sku number"
                          onKeyPress={handleKeyPress}
                          className="input-field-redux"
                          onChange={onChangeHandler}
                          value={skuNumber}
                          min={0}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-5.svg" alt="icon" />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Lots</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsLotActive(!isLotActive);
                              setIsActive(false);
                              setIsDistributorActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {productVal
                                  ? selectedLot
                                  : "Select product first"}
                              </p>
                            </div>

                            <img
                              className={
                                isLotActive && productVal
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src={arrow}
                              alt="arrow"
                            />
                          </div>
                          {isLotActive && productVal && (
                            <div className="dropdown-content" ref={refOne}>
                              {products &&
                                products
                                  .filter((data) => {
                                    return data.product == selected;
                                  })
                                  .map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelectedLot(option.lot);
                                            setIsLotActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p>{option.lot}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Quantity
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input quantity-input-wrap">
                        <input
                          name="amount"
                          className="input-field-redux"
                          type="number"
                          onKeyPress={handleKeyPress}
                          onChange={onChangeHandler}
                          value={amount}
                          min={0}
                        />
                        <div className="increment-icon">
                          <img
                            onClick={incrementAmount}
                            src="/images/icons/increment-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="decrement-icon">
                          <img
                            onClick={decrementAmount}
                            src="/images/icons/decrement-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-6.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Ship date
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>

                      <input
                        type="date"
                        min={today}
                        onChange={(e) => setShipDate(e.target.value)}
                      />
                    </div>

                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Expire date
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <input
                        type="date"
                        name="expire date"
                        className="input-field-redux expire-date-input"
                        value={
                          expireDate
                            ? moment(expireDate).format("YYYY-MM-DD")
                            : ""
                        }
                        disabled
                      />
                    </div> */}

                    {expireDate ? (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Expire date
                          <img src="/images/icons/info-icon.svg" alt="icon" />
                        </label>
                        <input
                          type="date"
                          name="expire date"
                          className="input-field-redux expire-date-input"
                          value={
                            expireDate
                              ? moment(expireDate).format("YYYY-MM-DD")
                              : ""
                          }
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label> Expire date </label>
                        <div className="create-product-input">
                          <input
                            className="input-field-redux"
                            value="Select Lot first"
                          />
                          <div className="input-icon-wrap">
                            <img src="/images/icons/input-8.svg" alt="icon" />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-sm-12 text-center">
                      <button
                        className="create-product-btn"
                        onClick={createShipHandler}
                        disabled={isLoading}
                      >
                        {isLoading ? "Processing..." : "Proceed"}
                      </button>

                      <div className="tutorial-wrap-btn tutorial-tab-etc">
                        <img
                          onClick={() => setShowTutorialModal(true)}
                          src="/images/icons/tutorial-icon.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="create-product-left-wrap">
            <div className="create-product-left">
              <div className="create-product-left-img">
                <img src="/images/shipping-img.png" alt="img" />
              </div>
              <div className="create-product-left-contant">
                {/* <h3>Welcome to the Shipping Creation Page </h3> */}

                <p>
                  In pharmaceutical distribution, Hyperledger Fabric's
                  'Shipping' tool streamlines product dispatch. Users specify
                  product, destination distributor, lot, tracking, quantity, and
                  ship date. Securely recorded on the blockchain, it ensures
                  compliant, efficient shipments in the supply chain.
                </p>

                <div className="toutorial-icon-wrap">
                  <img
                    onClick={() => setShowTutorialModal(true)}
                    src="/images/icons/tutorial-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GyanConsulting />
    </>
  );
};

export default Shipping;
