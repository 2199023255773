import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, Table, Badge } from "react-bootstrap";
import { serviceGet, servicePost } from "../../../services/api";
import arrow from "../../../images/down-arrow.png";
import moment from "moment";
import ProductListDrawer from "./Drawer/ProductListDrawer";
import { Drawer } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GyanConsulting from "./GyanConsulting";
import LoginTabs from "./LoginTabs";

const InventoryPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProject, setFilteredProject] = useState();
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Products");
  const [filterAmount, setFilterAmount] = useState("");
  const [filterLot, setFilterLot] = useState("");
  const selectedProduct = selected == "Products" ? "" : selected;
  const [totalLeftAmount, setTotalLeftAmount] = useState(0);

  const [open, setOpen] = useState(false);
  const [productinfo, setproductinfo] = useState();

  const history = useHistory();

  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }

  const showDrawer = async (productname) => {
    const getHistory = await servicePost("api/distributor/productdata", {
      product: productname,
    });

    setproductinfo(getHistory);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Get user form localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  // get all products
  const getAllProducts = async () => {
    try {
      const allProducts = await serviceGet("api/distributor/allproducts");
      setProducts(allProducts);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get Total Avalible Supply in same product
  const getAllSameProducts = async () => {
    try {
      const data = await servicePost("api/distributor/products", {
        product: selectedProduct,
      });
      // Sum the leftAmount of all objects in the response
      const leftAmounts = data.map((obj) => obj.leftAmount);
      const totalLeftAmount = leftAmounts.reduce((a, b) => a + b, 0);

      setTotalLeftAmount(totalLeftAmount);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllSameProducts();
  }, [selected]);

  // get uniqueProducts..
  const uniqueProducts = products.reduce((acc, current) => {
    if (!acc[current.product]) {
      acc[current.product] = { ...current }; // Copy the current object
    } else {
      // If the product already exists, add the amount to the existing object
      acc[current.product].amount += current.amount;
    }
    return acc;
  }, {});
  const uniqueProduct = Object.values(uniqueProducts);

  // Filter Product basis of Product lot and amount
  const filterTask = () => {
    const allData = [];
    var selectedProduct = selected == "Products" ? "" : selected;
    uniqueProduct &&
      uniqueProduct
        .filter((ele) => {
          if (
            selectedProduct === "" &&
            filterAmount === "" &&
            filterLot == ""
          ) {
            return ele;
          }
          // One filled fill and two empty
          else {
            if (selectedProduct && !filterAmount && !filterLot) {
              return ele.product === selectedProduct;
            } else if (filterAmount && !selectedProduct && !filterLot) {
              return Number(ele.amount) === Number(filterAmount);
            } else if (!filterAmount && !selectedProduct && filterLot) {
              return Number(ele.lot) === Number(filterLot);
            }
            // if two filled fill Product check
            else if (!filterLot && selectedProduct && filterAmount) {
              return (
                ele.product === selectedProduct &&
                Number(ele.amount) === Number(filterAmount)
              );
            } else if (filterLot && selectedProduct && !filterAmount) {
              return (
                Number(ele.lot) === Number(filterLot) &&
                ele.product === selectedProduct
              );
            }
            // if one filled fill
            else if (selectedProduct && !filterLot) {
              return (
                ele.amount === filterAmount && ele.product === selectedProduct
              );
            } else if (filterLot && !selectedProduct) {
              return (
                Number(ele.lot) === Number(filterLot) &&
                Number(ele.amount) === Number(filterAmount)
              );
            } else if (filterAmount && selectedProduct && filterLot) {
              return (
                Number(ele.amount) === Number(filterAmount) &&
                ele.product === selectedProduct &&
                Number(ele.lot) === Number(filterLot)
              );
            }
          }
        })
        .map((data) => {
          allData.push({
            product: data.product,
            manu: data.manufacturer,
            organization: data.organization,
            serial: data.serial,
            lot: data.lot,
            amount: data.amount,
            leftAmount: data.leftAmount,
            sku: data.skuNumber,
            expireDate: data.expireDate,
            gtin: data.gtin,
          });
        });
    setFilteredProject(allData);
  };

  useEffect(() => {
    filterTask();
  }, [products, filterAmount, filterLot, selected]);

  // filterData basis of userEmail
  const filterData = filteredProject?.filter((data) => {
    return data.organization == userData.email;
  });

  /// HandleKeyPress
  function handleKeyPress(event) {
    const regex = /^[0-9\b]+$/; // allows only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }
  return (
    <Fragment>
      <Drawer
        title="Product Details"
        width={980}
        placement="right"
        // onClose={onClose}
        open={open}
      >
        <div className="drawer-header-main-wrap">
          <div className="drawer-header-wrap">
            <div className="drawer-heading-wrap">
              <h3> Product Details </h3>
            </div>
            <div className="close-button-wrap">
              <img
                onClick={onClose}
                src="/images/icons/cross-white.svg"
                alt="icon"
              />
            </div>
          </div>
        </div>
        <ProductListDrawer productinfo={productinfo} />
      </Drawer>

      <div className="header-main-wrapper header-desktop-main-wrapper">
        <div className="header-main-wrap">
          <div className="header-logo">
            <h3> Product List </h3>
          </div>

          <div className="logout-logo-wrap" onClick={onLogout}>
            <svg
              id="Logout"
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="41"
              viewBox="0 0 41 41"
            >
              <rect
                id="Rectangle_906"
                data-name="Rectangle 906"
                width="41"
                height="41"
                fill="rgba(255,255,255,0)"
              />
              <rect
                id="vuesax_bold_logout"
                data-name="vuesax/bold/logout"
                width="30"
                height="30"
                transform="translate(37 35) rotate(-180)"
                fill="none"
              />
              <g
                id="vuesax_bold_logout-2"
                data-name="vuesax/bold/logout"
                transform="translate(37.479 35.479) rotate(-180)"
              >
                <g id="logout-2" data-name="logout">
                  <path
                    id="Vector"
                    d="M16.757,6.708V19.091c0,4.128-2.58,6.708-6.708,6.708H6.708C2.58,25.8,0,23.219,0,19.091V13.867H8.062a.967.967,0,0,0,0-1.935H0V6.708C0,2.58,2.58,0,6.708,0h3.354C14.19,0,16.769,2.58,16.757,6.708Z"
                    transform="translate(11.61 2.58)"
                    fill="#4b4b4b"
                  />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M3.3,4.318H9.026V6.253H3.3l2.67,2.67A.967.967,0,1,1,4.6,10.291L.281,5.969a.973.973,0,0,1,0-1.367L4.6.281a.973.973,0,0,1,1.367,0,.936.936,0,0,1,.284.684.957.957,0,0,1-.284.684L3.3,4.318Z"
                    transform="translate(2.583 10.194)"
                    fill="#4b4b4b"
                  />
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H30.959V30.959H0Z"
                    transform="translate(30.959 30.959) rotate(180)"
                    fill="none"
                    opacity="0"
                  />
                </g>
              </g>
            </svg>
            <p> Logout </p>
          </div>
        </div>
      </div>

      <div className="header-main-wrapper header-mobile-main-wrapper">
        <div className="mobile-header-main-wrap">
          <h3> Product List </h3>
        </div>
        <div className="mobile-project-main-logo">
          <img src="/images/icons/manu-small-logo.png" alt="logo" />
        </div>
      </div>
      <div className="mobile-product-list-filter">
        <div className="create-new-product-btn">
          <div className="select-box-wrapper inventory-filter">
            <div className="react-dropdown">
              <div
                className="react-dropdown-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsActive(!isActive);
                }}
              >
                <div className="selected-item">
                  <p> {selected} </p>
                </div>

                <img
                  className={
                    isActive
                      ? "dropdown-down-arrow rotate-arrow"
                      : "dropdown-down-arrow"
                  }
                  src={arrow}
                  alt="arrow"
                />
              </div>
              {isActive && (
                <div className="dropdown-content">
                  <div
                    onClick={(e) => {
                      setSelected("Products");
                      setIsActive(false);
                    }}
                    className="dropdown-item"
                  >
                    <p> Clear filter </p>
                  </div>
                  {uniqueProduct &&
                    uniqueProduct.map((option, index) => {
                      return (
                        <Fragment key={index}>
                          <div
                            onClick={(e) => {
                              setSelected(option.product);
                              setIsActive(false);
                            }}
                            className="dropdown-item"
                          >
                            <p> {option.product} </p>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="product-list-table-wrapper">
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                <img
                  className="table-product-icon"
                  src="/images/icons/product-icon.svg"
                  alt="icon"
                />
                Product
              </th>
              <th>Quantity</th>
              <th>Manufacturer</th>
              <th>Serial Numbers</th>
              <th>Sku Numbers</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {uniqueProduct?.length === 0 ? (
              <tr>
                <td
                  style={{ textAlign: "center", fontSize: "18px" }}
                  colSpan={8}
                >
                  No Data Found
                </td>
              </tr>
            ) : (
              filteredProject?.map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td> {data.product} </td>
                      <td className="color-primary"> {data.amount} </td>
                      <td>{data.manu}</td>
                      <td>{data.serial}</td>
                      <td className="color-primary">{data.sku}</td>
                      <td>
                        <img
                          onClick={() => showDrawer(data.product)}
                          src="/images/icons/view-icon.svg"
                          alt="icon"
                        />
                      </td>
                      {/* <td className="color-primary"> {data.lot} </td>
                    <td className="color-primary">{data.leftAmount}</td> */}
                      {/* <td className="color-primary">
                      {moment(data.expireDate).format("YYYY-MM-DD")}
                    </td>
                    <td className="color-primary"> {data.gtin} </td>
                    <td className="color-primary"> {data.transactionId} </td> */}
                    </tr>
                  </>
                );
              })
            )}
          </tbody>
        </Table>
        <LoginTabs />
      </div>
      <GyanConsulting />
    </Fragment>
  );
};

export default InventoryPage;
