/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useLocation } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import ProductListTutModal from "../../components/Modals/ProductListTutModal";
import ShippingTutModal from "../../components/Modals/ShippingTutModal";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import ExplorerTutModal from "../../components/Modals/ExplorerTutModal";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const [showExTutorialModal, setShowExTutorialModal] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [showShipHistoryModal, setShowShipHistoryModal] = useState(false);
  const location = useLocation();
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const history = useHistory();

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);

    // Add a listener to detect route changes
    const unlisten = history.listen(() => {
      // Remove the 'menu-toggle' class when the route changes
      aaa.classList.remove("menu-toggle");
    });

    // Clean up the listener when the component unmounts
    return () => {
      btn.removeEventListener("click", toggleFunc);
      handleheartBlast.removeEventListener("click", heartBlast);
      unlisten(); // Clean up the route change listener
    };
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = location.pathname;

  ///////////////
  const shiptutorial = () => {
    // props.onHide();
    try {
      const tutorials = localStorage.getItem("shiptutorial");
      console.log("tutorials", tutorials);
      if (tutorials == null) {
        setShowShipHistoryModal(true);
      } else {
        setShowShipHistoryModal(false);
      }

      // setShowShipHistoryModal(true);
    } catch (err) {}
  };

  ///////////////
  const productutorial = () => {
    // props.onHide();
    try {
      const tutorials = localStorage.getItem("producttutorial");
      console.log("producttutorial", tutorials);
      if (tutorials == null) {
        setShowTutorialModal(true);
      } else {
        setShowTutorialModal(false);
      }

      // setShowShipHistoryModal(true);
    } catch (err) {}
  };

  // path = path.split("/");
  // path = path[path.length - 1];

  /// Active menu
  let deshBoard = [
      "/",
      // "create-product",
      // "Shipping",
      // "inventory",
      // "shiping-history",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = ["redux-form", "redux-wizard", "todo"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];

  // const history = useHistory();

  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }

  return (
    <>
      <ExplorerTutModal
        show={showExTutorialModal}
        onHide={() => setShowExTutorialModal(false)}
      />
      <ProductListTutModal show={showTutorialModal} onHide={productutorial} />
      <ShippingTutModal show={showShipHistoryModal} onHide={shiptutorial} />
      <div
        className={`deznav 
      ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? scrollPosition > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="deznav-scroll">
          <div className="sidebar-menu-wrapper">
            <div className="sidebar-logo-wrapper">
              <img
                className="hyper-big-logo"
                src="/images/icons/manu-logo.png"
                alt="logo"
              />
              <img
                className="manu-small-logo"
                src="/images/icons/manu-small-logo.png"
                alt="logo"
              />
            </div>

            <MM className="metismenu" id="menu">
              <li
                className={`${
                  path === "/" || path === "/dashboard" ? "mm-active" : ""
                }`}
              >
                <div className="active-icon-line"></div>
                <Link className="has-arrow ai-icon" to="/">
                  {path === "/" || path === "/dashboard" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/dashbord-icon.png"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/dashbord-gray.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>

              <li
                className={`${path === "/inventory" ? "mm-active" : ""}`}
                onClick={productutorial}
              >
                <div className="active-icon-line"></div>
                <Link className="has-arrow ai-icon" to="/inventory">
                  {path === "/inventory" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/product-active.png"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/product-list.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text"> Products List </span>
                </Link>
              </li>
              <li
                className={`${path === "/Shipping" ? "mm-active" : ""}`}
                onClick={shiptutorial}
              >
                <div className="active-icon-line"></div>
                <Link className="has-arrow ai-icon" to="/Shipping">
                  {path === "/Shipping" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-active.png"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-gray.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">Shipping</span>
                </Link>
              </li>

              <li
                className={`${path === "/shiping-history" ? "mm-active" : ""}`}
                // onClick={() => setShowShipHistoryModal(true)}
              >
                <div className="active-icon-line"></div>
                <Link className="has-arrow ai-icon" to="/shiping-history">
                  {path === "/shiping-history" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-hist-active.png"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-history.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text"> Shipping History</span>
                </Link>
              </li>
            </MM>
            <div className="copyright">
              <p>
                {/* <strong>Innap Hotel Admin</strong> © 2021 All Rights Reserved */}
              </p>
              <p className="fs-12">
                <span className="heart"></span>
              </p>
            </div>
          </div>

          <div className="sidebar-bottom-wrapper small-screen-bottom-wrapper">
            <div className="sidebar-explorer-logo-wrap">
              <div className="explorer-left-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-big-logo"
                  src="/images/icons/explorer-logo.png"
                  alt="logo"
                />

                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-small-logo"
                  src="/images/icons/hyper-small.png"
                  alt="logo"
                />
              </div>
              <div className="explorer-right-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  src="/images/icons/explorer-arrow.svg"
                  alt="logo"
                />{" "}
              </div>
            </div>

            <div className="sidebar-logout-wrapper">
              <div className="sidebar-logout-wrap">
                <button onClick={onLogout}>
                  <img src="/images/icons/logout-icon.svg" alt="img" />
                  <p> Logout </p>
                </button>
                <div className="mobile-view-icon">
                  <img
                    onClick={onLogout}
                    src="/images/icons/logout-black.svg"
                    alt="icon"
                  />
                  {/* <svg
                    onClick={onLogout}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="vuesax_bold_logout"
                      data-name="vuesax/bold/logout"
                      transform="translate(24 24) rotate(-180)"
                    >
                      <rect
                        id="vuesax_bold_logout-2"
                        data-name="vuesax/bold/logout"
                        width="24"
                        height="24"
                        fill="none"
                      />
                      <g
                        id="vuesax_bold_logout-3"
                        data-name="vuesax/bold/logout"
                      >
                        <g id="logout">
                          <path
                            id="Vector"
                            d="M12.99,5.2v9.6A4.832,4.832,0,0,1,7.79,20H5.2A4.832,4.832,0,0,1,0,14.8V10.75H6.25a.75.75,0,0,0,0-1.5H0V5.2A4.832,4.832,0,0,1,5.2,0H7.8A4.819,4.819,0,0,1,12.99,5.2Z"
                            transform="translate(9 2)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M2.557,3.348H7v1.5H2.557l2.07,2.07a.75.75,0,0,1-1.06,1.06L.217,4.627a.754.754,0,0,1,0-1.06L3.567.217a.754.754,0,0,1,1.06,0,.725.725,0,0,1,.22.53.742.742,0,0,1-.22.53l-2.07,2.07Z"
                            transform="translate(2.003 7.902)"
                            fill="#fff"
                          />
                          <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M0,0H24V24H0Z"
                            transform="translate(24 24) rotate(180)"
                            fill="none"
                            opacity="0"
                          />
                        </g>
                      </g>
                    </g>
                  </svg> */}
                </div>
              </div>
            </div>
          </div>

          <div className="sidebar-bottom-wrapper desktop-sidebar-bottom">
            <div className="sidebar-explorer-logo-wrap">
              <div className="explorer-left-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-big-logo"
                  src="/images/icons/explorer-logo.png"
                  alt="logo"
                />

                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-small-logo"
                  src="/images/icons/hyper-small.png"
                  alt="logo"
                />
              </div>
              <div className="explorer-right-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  src="/images/icons/explorer-arrow.svg"
                  alt="logo"
                />
              </div>
            </div>
            {/* <div className="gyan-solution-wrap">
              <a href="https://www.gyan.solutions/" target="_blank">
                <img
                  className="gyan-big-logo"
                  src="/images/icons/gyan-logo.png"
                  alt="logo"
                />
              </a>
              <a href="https://www.gyan.solutions/" target="_blank">
                <img
                  className="gyan-small-logo"
                  src="/images/icons/gyan-small-logo.png"
                  alt="logo"
                />
              </a>
            </div>
            <p className="gyan-solution-copyright">
              © 2023 Gyan.Solutions, all right reserved
            </p> */}
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default SideBar;
