import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const LoginTutModal = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleProceedClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const history = useHistory();
  const setLocalStorageData = () => {
   
    props.onHide();
    
  };
  return (
    <>
      <Modal
        {...props}
        dialogClassName="login-tutorial-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="login-tutorial-main-wrapper">
            <div className="login-tutorial-main-wrap">
              <div className="close-drawer-wrap">
                <img
                  onClick={() => props.onHide()}
                  src="/images/icons/cross-icon.svg"
                />
              </div>
              {currentStep === 1 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-wrap">
                      <div className="login-manu-logo">
                        <img src="/images/icons/manu-logo.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap">
                        <h3> Welcome to the Distributor ERP </h3>
                        <p>
                          Participating in a blockchain-based system benefits
                          pharmaceutical distributors by reinforcing compliance
                          with stringent regulations like Good Distribution
                          Practices (GDP). This tech securely logs product
                          movements, offering real-time supply chain visibility.
                          Distributors can swiftly pinpoint and handle recalled
                          batches, reducing risks and safeguarding their
                          reputation. Automated compliance processes streamline
                          regulatory adherence, while blockchain's data security
                          protects sensitive intellectual property. This
                          unwavering commitment to quality and transparency
                          fosters trust among stakeholders, enabling
                          distributors to deliver safe, genuine products and
                          attain operational excellence in pharmaceuticals.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button login-center-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-wrap">
                      <div className="login-manu-logo">
                        <img src="/images/icons/manu-logo.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap">
                        <h3> Welcome to the Distributor ERP </h3>
                        <p>
                          Participating in a blockchain-based system benefits
                          pharmaceutical distributors by reinforcing compliance
                          with stringent regulations like Good Distribution
                          Practices (GDP). This tech securely logs product
                          movements, offering real-time supply chain visibility.
                          Distributors can swiftly pinpoint and handle recalled
                          batches, reducing risks and safeguarding their
                          reputation. Automated compliance processes streamline
                          regulatory adherence, while blockchain's data security
                          protects sensitive intellectual property. This
                          unwavering commitment to quality and transparency
                          fosters trust among stakeholders, enabling
                          distributors to deliver safe, genuine products and
                          attain operational excellence in pharmaceuticals.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 2 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Hyperledger acts as digital adhesive, connecting
                          software systems akin to a bridge amid islands. It
                          silently upholds your ERP as the core business hub,
                          ensuring secure data exchange amid systems, promoting
                          collaboration sans extensive adjustments or extra
                          apps.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/dashboard-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Hyperledger acts as digital adhesive, connecting
                          software systems akin to a bridge amid islands. It
                          silently upholds your ERP as the core business hub,
                          ensuring secure data exchange amid systems, promoting
                          collaboration sans extensive adjustments or extra
                          apps.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/mobile-dashboard-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Forth Step */}
              {currentStep === 3 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Product List </h3>
                        <p>
                          In pharmaceutical distribution, securely record
                          product details on the Hyperledger Fabric blockchain
                          for data integrity. Advantages include bolstered
                          compliance and enhanced traceability, elevating
                          patient safety and product authenticity.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/product-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Product List </h3>
                        <p>
                          In pharmaceutical distribution, securely record
                          product details on the Hyperledger Fabric blockchain
                          for data integrity. Advantages include bolstered
                          compliance and enhanced traceability, elevating
                          patient safety and product authenticity.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-product-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Fifth Step */}
              {currentStep === 4 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping </h3>
                        <p>
                          Hyperledger Fabric transforms pharmaceutical logistics
                          with secure, transparent blockchain records. It
                          streamlines processes, heightens compliance, reduces
                          risks, ensuring authenticity, benefiting distributors,
                          manufacturers, and users.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/shipping-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping </h3>
                        <p>
                          Hyperledger Fabric transforms pharmaceutical logistics
                          with secure, transparent blockchain records. It
                          streamlines processes, heightens compliance, reduces
                          risks, ensuring authenticity, benefiting distributors,
                          manufacturers, and users.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-shipping-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Sixth Step */}
              {currentStep === 5 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping History </h3>
                        <p>
                          In pharmaceutical distribution, Hyperledger Fabric
                          guarantees secure, transparent, tamper-proof shipping
                          records. This elevates compliance, authenticity, and
                          patient safety, nurturing unwavering data integrity
                          and trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/shipping-history-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping History </h3>
                        <p>
                          In pharmaceutical distribution, Hyperledger Fabric
                          guarantees secure, transparent, tamper-proof shipping
                          records. This elevates compliance, authenticity, and
                          patient safety, nurturing unwavering data integrity
                          and trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/mobile-shipping-history-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Sixth Step */}

              {currentStep === 6 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Explorer </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/explorer-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button
                            className="go-dashboard"
                            onClick={setLocalStorageData}
                          >
                            Go to Dashboard
                          </button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Explorer </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-explorer-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button
                            className="go-dashboard"
                            onClick={() => props.onHide()}
                          >
                            Go to Dashboard
                          </button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginTutModal;
