import React from "react";

import { Link, useLocation } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avatar/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";

const Header = ({ onNote }) => {
  const location = useLocation();
  var path = location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification right-header-wrap">
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                >
                  {/* <img src={profile} width={20} alt="" /> */}
                  <i className="flaticon-025-dashboard"></i>
                  <div className="header-info ms-3">
                    <span>Pages</span>
                    {/* <small>Superadmin</small> */}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >
                  <ul>
                    <li>
                      <Link
                        className={`${path === "" ? "mm-active" : "dashboard"}`}
                        to="/dashboard"
                      >
                        <span className="ms-2"> Dashboard </span>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={`${
                          path === "guest-detail" ? "mm-active" : ""
                        }`}
                        to="/shipping"
                      >
                        <span className="ms-2"> Shipping</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${path === "inventory" ? "mm-active" : ""}`}
                        to="/inventory"
                      >
                        <span className="ms-2"> Inventory</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${
                          path === "shiping-history" ? "mm-active" : ""
                        }`}
                        to="/shiping-history"
                      >
                        <span className="ms-2"> Shipping history </span>
                      </Link>
                    </li>
                  </ul>
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
